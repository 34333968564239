/* ServerCreator.css */
.server-creator {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
}

.server-creator form {
  display: flex;
  flex-direction: column;
}

.server-creator h2 {
  text-align: center;
  margin-bottom: 20px;
}

.server-creator .form-section {
  margin-bottom: 20px;
}

.server-creator label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.server-creator input,
.server-creator select {
  margin-left: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 1;
}

.server-creator button {
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.server-creator .submit-button {
  background-color: #28a745;
  margin-top: 20px;
  padding: 10px 15px; /* Increase the padding for more height */
}

.server-creator .submit-button:hover {
  background-color: #218838;
}

.server-creator .tooltip {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}

.server-creator .tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.server-creator .tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.server-creator .toggle-advanced {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #007bff;
  margin-bottom: 20px;
}

.server-creator .toggle-advanced:hover {
  color: #0056b3;
}

.server-creator .toggle-advanced svg {
  margin-left: 5px;
}
